* {
  box-sizing: border-box;
}

h1 {
  margin: 0;
}

h2,
h3 {
  margin: 0 0 10px 0;
}

section {
  width: 100%;
}

p {
  margin: 10px 0;
}

a {
  color: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
}

input {
  width: 100%;
  min-width: 0;
  height: 100%;
  font-size: 16px;
  background: transparent;
  border: 0;
  padding: 0 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

button {
  height: 40px;
  background: transparent;
  border: 0;
  border-radius: 4px;
  cursor: pointer;

  &.action {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  &.add-icon {
    display: block;
    width: 60px;
    height: 60px;
    margin: auto;
  }

  svg {
    width: 50% !important;
    height: 50% !important;
    color: $primary-color-3;
  }

  &.primary {
    color: $white;
    background: $primary-color-3;
    padding: 10px 20px;
  }
}

.heading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: $primary-color-3;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  left: 20px;

  svg {
    width: 30px !important;
    height: 30px;
    color: $white;
  }
}

.hide {
  visibility: hidden;
}

.none {
  display: none !important;
}

section,
.difference {
  max-width: $width;
  background-color: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 8px 10px;
  margin: 0 auto 60px;
  position: relative;
  @include jagged-top;
  @include jagged-bottom;
}

.accordion-icon {
  transition: transform 0.8s;

  &.active {
    transform: rotate(90deg);
  }
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.8s;
}
