@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

h1,
h2,
h3 {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  text-align: center;
  color: $primary-color-1;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

body,
input,
button,
.summary strong {
  font-family: 'Roboto Mono', monospace;
  font-size: 15px;
}

strong {
  font-size: 20px;
}

input {
  &[type='number'] {
    text-align: right;
  }
}
