.about {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 90px;
  margin-bottom: 0;

  &::after {
    display: none;
  }

  .heading-icon {
    cursor: pointer;
  }
}
