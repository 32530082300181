.header {
  padding: 0 10px;

  &::before {
    display: none;
  }

  #installer {
    opacity: 0;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    cursor: default;

    &.show {
      opacity: 1;
      transition: opacity 0.4s;
      cursor: pointer;
    }
  }

  .button-group {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
  }

  .first-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 54px;
    right: 20px;
    z-index: 1;
    animation: bounce 1s infinite alternate;

    svg {
      width: 20px;
      height: 20px;
      color: $primary-color-1;
      margin-bottom: 4px;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}

// tour
#___reactour {
  .reactour__helper {
    padding: 20px 30px;
  }

  span[data-tour-elem='badge'] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
    box-shadow: none;
    padding: 0;
    top: -15px;
    left: 10px;
  }

  .reactour__close,
  button[data-tour-elem='left-arrow'],
  button[data-tour-elem='right-arrow'] {
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    background: $primary-color-3;
    border-radius: 50%;

    svg {
      color: $white;
    }
  }

  .reactour__close {
    top: 10px;
    right: 10px;
  }

  p {
    svg {
      color: $primary-color-3;
    }
  }

  div[data-tour-elem='controls'] {
    justify-content: space-between;
    margin-top: 10px;
  }

  button[data-tour-elem='dot'] {
    width: 16px;
    height: 16px;
  }

  .button-group {
    button {
      display: flex;
      align-items: center;
      margin: 0 4px;
    }

    span {
      margin-right: 8px;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
      color: $white;

      &[data-icon='android'] {
        padding-top: 2px;
      }
    }
  }

  .tour-done {
    display: block;
    margin: auto;
  }
}
