.difference {
  margin-bottom: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  .difference-container,
  .items-left {
    padding-left: 50px;
    padding-right: 50px;
  }

  .difference-container {
    display: flex;
    padding-right: 50px;
    padding-left: 0;
  }

  .difference-content {
    color: $primary-color-1;
    padding-left: 10px;
    margin: 7px 0;

    &.difference-display {
      color: $red;
    }
  }

  li {
    justify-content: space-between;
    margin-bottom: 10px;
  }

  p {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
