.summary {
  .stats {
    padding-left: 50px;
    padding-right: 50px;
  }

  ul {
    margin-bottom: 10px;
  }

  li {
    justify-content: space-between;
  }

  p {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
