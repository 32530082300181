$total: 400;
$time: 4s;

.easter-egg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black url('../../images/logo.png') no-repeat center;
  background-size: 160px;
  position: fixed;
  z-index: 2;
  transform-style: preserve-3d;
  perspective: 800px;
  animation: boom 441ms infinite;

  @keyframes boom {
    0% {
      background-size: 160px;
    }

    2% {
      background-size: 220px;
    }
  }

  .tri {
    height: 0;
    width: 0;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  @for $i from 1 through $total {
    $size: random(50) * 1px;
    $rotate: random(360) * 1deg;

    .tri:nth-child(#{$i}) {
      border-top: $size solid hsla(random(360), 100%, 50%, 1);
      border-right: $size solid transparent;
      border-left: $size solid transparent;
      margin-left: -$size/2;
      margin-top: -$size/2;
      transform: rotate($rotate) translate3d(0, 0, 1000px);
      animation: anim#{$i} $time infinite linear;
      animation-delay: $i * -($time/$total);
      opacity: 0;
    }

    @keyframes anim#{$i} {
      0% {
        opacity: 1;
        transform: rotate($rotate * 1.5)
          translate3d(random(1000) * 1px, random(1000) * 1px, -2000px);
      }
    }
  }
}
