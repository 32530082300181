$width: 360px;
$white: #fff;
$red: firebrick;
$primary-color-1: #003840;
$primary-color-1a: rgba(#003840, 0.8); // #40676D
$primary-color-2: #005a5b;
$primary-color-3: #007369;
$primary-color-4: #008c72;
$primary-color-5: #02a676;
$primary-color-5a: rgba(#02a676, 0.6); // #7CCAAF

@mixin jagged {
  content: '';
  width: 100%;
  height: 15px;
  background-repeat: repeat-x;
  background-size: 20px 20px;
  position: absolute;
  left: 0;
}

@mixin jagged-top {
  &::before {
    @include jagged;
    background-image: linear-gradient(45deg, $white 10px, transparent 0),
      linear-gradient(-45deg, $white 10px, transparent 0);
    top: -15px;
  }
}

@mixin jagged-bottom {
  &::after {
    @include jagged;
    background-image: linear-gradient(-135deg, $white 10px, transparent 0),
      linear-gradient(135deg, $white 10px, transparent 0);
    bottom: -15px;
  }
}

@mixin underline {
  content: '';
  width: 120px;
  height: 1px;
  background: $primary-color-3;
  position: absolute;
  bottom: 0;
}

@mixin underline-left {
  &::before {
    @include underline;
    left: 40px;
  }
}

@mixin underline-right {
  &::after {
    @include underline;
    right: 40px;
  }
}
