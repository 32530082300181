.receipt {
  .select-items {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 53px;
    padding: 0 10px;
    margin-bottom: 10px;

    p {
      margin: 0;
    }

    button {
      height: auto;
    }
  }

  li {
    align-items: center;
    justify-content: space-between;
    height: 40px;

    &.tax-tip {
      padding-left: 10px;
      position: relative;
      @include underline-right;

      &::after {
        right: 0;
      }

      span {
        display: flex;
        align-items: center;
        height: 100%;
      }

      input {
        max-width: 130px;
      }
    }

    &.total {
      padding: 0 10px;
    }
  }

  .item-list li {
    position: relative;
    @include underline-left;
    @include underline-right;
  }

  .item-list li {
    &.item-left {
      &::before,
      &::after {
        background: $red;
      }
    }
  }

  .add-item {
    display: block;
    margin: auto;
  }

  .done {
    display: block;
    margin: 10px auto;
  }

  .button-group {
    .primary {
      width: 26%;
      margin: 0 4px;
    }
  }

  .item-total {
    padding: 0 40px;
  }
}
