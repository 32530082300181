.App {
  background-attachment: fixed;

  &.theme-1 {
    background-image: linear-gradient(
      45deg,
      $primary-color-1a,
      $primary-color-5a
    );
  }

  &.theme-2 {
    background-image: url(../../images/wood.jpg);
    background-position: center top;
  }
}
