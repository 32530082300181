.people {
  padding-bottom: 0;

  p {
    display: flex;
    justify-content: space-between;
  }

  .person-list {
    li {
      flex-direction: column;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .person-name,
  .person-total {
    display: flex;
    height: 40px;
  }

  .person-name {
    position: relative;
    @include underline-left;
  }

  .person-subtotal {
    display: flex;
    flex-direction: column;
    padding: 0 50px;

    li {
      display: block;
      margin: 10px 0;

      div:nth-child(2) {
        text-align: right;
      }
    }
  }

  .person-total {
    padding-right: 50px;

    p {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      padding-left: 10px;
      margin: 0;
    }
  }
}
